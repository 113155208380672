import { Button } from 'ant-design-vue'
import DrawerForm from '@/components/DrawerForm'
import apiTool from '@/command/apiTool'

function onOpen({ records, update }) {
  apiTool.showDrawer({
    title: '编辑',
    view: DrawerForm,
    width: '600px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      form: { ...records },
      data: [
        {
          form: [
            {
              name: '标题',
              labelCol: 24,
              wrapperCol: 24,
              key: 'name',
              type: 'input'
            },
            {
              name: 'ICON选中状态',
              labelCol: 24,
              type: 'iconUpload',
              typeData: [
                {
                  key: 'image2',
                  desc: '@2x   xhdpi <br/>上传尺寸100*100px'
                },
                {
                  key: 'image3',
                  desc: '@3x   xxhdpi <br/>上传尺寸150*150px'
                }
              ]
            },
            {
              name: 'ICON未选中状态',
              labelCol: 24,
              type: 'iconUpload',
              typeData: [
                {
                  key: 'image22',
                  desc: '@2x   xhdpi <br/>上传尺寸100*100px'
                },
                {
                  key: 'image23',
                  desc: '@3x   xxhdpi <br/>上传尺寸150*150px'
                }
              ]
            }
          ]
        }
      ]
    },
    success({ data, setHidden }) {
      update(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

export default function() {
  const { update } = arguments[0]
  return {
    type: 'table',
    url: '/config/farmButtomMenu/list',
    edit: '/config/farmButtomMenu/update',
    props: {
      columns: [
        {
          dataIndex: 'name',
          width: '80%',
          align: 'left',
          customRender: function customRender(text, records, index, h) {
            return (
              <div>
                <img src={records.image2} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                <span>{text}</span>
              </div>
            )
          }
        },
        {
          width: '110px',
          customRender: function customRender(text, records, index, h) {
            return (
              <Button onClick={() => onOpen({ update, records })} ghost={true} type="primary">
                编辑
              </Button>
            )
          }
        }
      ]
    }
  }
}
